@import "../mixin";
@import "../variables";

.form {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 50px;
  @include center(column);
  gap: 24px;
  border-radius: 8px;

  div {
    position: relative;
    justify-content: flex-start;

    label {
      font-weight: 500;
      justify-self: flex-start;

      p {
        width: 100%;
      }
    }

    input,
    textarea {
      justify-self: flex-start;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 30px 20px;
  }

  @media screen and (max-width: 480px) {
    padding: 30px 10px;
    gap: 16px;
  }
}

.input_group {
  position: relative;
  z-index: 2;
  width: 100%;
  @include center;
  gap: 5px;

  .label {
    @include center;
    gap: 12px;
  }

  label {
    font-size: 18px;
    font-weight: 600;
    @include center;
    justify-content: flex-start;
    flex-basis: 45%;

    p {
      order: 2;
    }

    i {
      order: 1;

      svg {
        height: 40px;
        width: 40px;
        stroke: #d9d9d9;
      }
    }

    &[aria-disabled="true"] {
      i {
        svg {
          stroke: darken(#d9d9d9, 50%);
        }
      }
    }
  }

  input,
  textarea {
    font-family: "Lexend Tera", sans-serif;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 5px 12px;
    width: 100%;
    font-size: 12px;
    background-color: #d9d9d9;
    transition: border 0.3s ease;
    flex-basis: 55%;
    resize: vertical;

    &:focus {
      border: 2px solid $neon_green;
      outline: none;
    }

    &::placeholder {
      color: #d9d9d9;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &:disabled {
      background-color: darken(#d9d9d9, 50%);
      color: #d9d9d9;
    }

    &[aria-errormessage="true"] {
      border: 1px solid red;
    }
  }

  .dropdown_content {
    @include center;
    justify-self: flex-start;
    justify-content: flex-start;
    width: 50%;
    flex-basis: 55%;
    gap: 10px;
  }

  @media screen and (max-width: 478px) {
    margin-bottom: 12px;
    gap: 4px;

    input,
    textarea {
      padding: 5px 8px;
      font-size: 10px;
    }

    label {
      width: 100%;
      font-size: 14px;

      i {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}

.multi_input_group {
  position: relative;
  z-index: 2;
  width: 100%;
  @include center;
  justify-content: space-between;
  gap: 5px;

  .label {
    @include center;
    gap: 12px;
  }

  label {
    font-size: 18px;
    font-weight: 600;
    @include center;
    justify-content: flex-start;
    width: 30%;

    p {
      order: 2;
    }

    i {
      order: 1;

      svg {
        height: 40px;
        width: 40px;
        stroke: #d9d9d9;
      }
    }

    &[aria-disabled="true"] {
      i {
        svg {
          stroke: darken(#d9d9d9, 50%);
        }
      }
    }
  }

  & > div {
    @include center;
    justify-content: flex-start;
    gap: 10px;
    width: 70%;
    max-width: 70%;

    input,
    textarea {
      font-family: "Lexend Tera", sans-serif;
      border: 2px solid #d9d9d9;
      border-radius: 8px;
      padding: 5px 12px;
      font-size: 12px;
      background-color: #d9d9d9;
      transition: border 0.3s ease;
      resize: vertical;
      width: 50%;

      &:focus {
        border: 2px solid $neon_green;
        outline: none;
      }

      &::placeholder {
        color: #d9d9d9;
      }

      &:focus::placeholder {
        color: transparent;
      }

      &:disabled {
        background-color: darken(#d9d9d9, 50%);
        color: #d9d9d9;
      }

      &[aria-errormessage="true"] {
        border: 1px solid red;
      }
    }

    .dropdown_content {
      @include center;
      justify-self: flex-start;
      justify-content: flex-start;
      width: 50%;
    }
  }

  @media screen and (max-width: 478px) {
    margin-bottom: 12px;
    gap: 4px;

    & > div {
      input,
      textarea {
        padding: 5px 8px;
        font-size: 10px;
      }

      label {
        width: 100%;
        font-size: 14px;

        i {
          svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}

.input_preview {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  @include center(column);
  gap: 5px;

  .label {
    @include center;
    gap: 12px;
  }

  .row {
    width: 100%;
    @include center;
    justify-content: space-between;
    column-gap: 10px;

    label {
      width: 70%;
      max-width: 70%;
    }
  }

  .radios {
    justify-content: flex-start;

    label {
      width: 40%;
      justify-content: flex-start;
    }

    input {
      width: unset;
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    @include center;

    p {
      order: 2;
    }

    i {
      order: 1;

      svg {
        height: 40px;
        width: 40px;
        stroke: #d9d9d9;
      }
    }

    &[aria-disabled="true"] {
      i {
        svg {
          stroke: darken(#d9d9d9, 50%);
        }
      }
    }
  }

  input,
  textarea {
    font-family: "Lexend Tera", sans-serif;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    font-size: 8px;
    background-color: #d9d9d9;
    transition: border 0.3s ease;

    &:focus {
      border: 2px solid $neon_green;
      outline: none;
    }

    &::placeholder {
      color: #d9d9d9;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &:disabled {
      background-color: darken(#d9d9d9, 50%);
      color: #d9d9d9;
    }

    &[aria-errormessage="true"] {
      border: 1px solid red;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    min-height: 50px;
  }

  @media screen and (max-width: 478px) {
    margin-bottom: 12px;
    gap: 4px;

    input,
    textarea {
      padding: 5px 8px;
      font-size: 10px;
    }

    label {
      width: 100%;
      font-size: 14px;

      i {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }

    img {
      min-height: 20px;
    }
  }
}

.note_box {
  padding: 10px;
  background-color: lighten($color: rgb(0, 34, 16), $amount: 2%);
  outline-width: 2px;
  outline-style: dashed;
  outline-offset: 3px;
  outline-color: $neon_green;
  border-radius: 8px;

  ul {
    word-wrap: break-word;
    padding: 0;
    padding-left: 1.25rem;
    margin: 0;
    font-size: 12px;

    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &::marker {
        color: $neon_green;
      }
    }
  }
}
