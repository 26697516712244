@import "../mixin";
@import "../variables";

.mint_gate {
  @include center(column);
  width: 100%;
  gap: 60px;
  background-color: #001C0D;
  margin-top: 100px;
  padding-bottom: 100px;

  h1 {
    font-size: 32px;
    z-index: 1;
  }

  .mint_body {
    @include center;
    align-items: flex-start;
    gap: 30px;
    position: relative;
    z-index: 2;
    padding: 0 20px;

      .form_wrapper {
        border-radius: 8px;
  
        form {
          .attribute_input {
            width: 100%;
            @include center;
            justify-content: flex-start;


  
            label {
              font-weight: 600;
              font-size: 18px;
              flex-basis: 45%;

              @media screen and (max-width:500px){
                font-size: 14px;
              }
            }
  
            .double_select {
              @include center;
              flex-direction: row;
              justify-content: flex-start;
              justify-self: flex-start;
              flex-basis: 55%;
              gap: 10px;
  
              .attribute {
                position: relative;
                @include center(column);
                align-items: flex-start;
                gap: 4px;
  
                label {
                  font-size: 0.9rem;
                  font-weight: 200;
                }
  
                .loader_container {
                  right: 1px;
                  top: calc(50% + 0px);
                }
              }
            }
  
          }
        }
      }

      .sample{
        margin-top: 50px;

        .preview{
          padding-left: 20px;
        }
      }

    .cart_wrapper {
      @include center(column);
      gap: 24px;
      border-radius: 8px;

      & > div {
        text-align: center;

        button {
          font-size: 16px;
          padding: 12px 38px;
        }
      }

      .buy_link {
        font-size: 0.8rem;
        margin-top: 16px;
        color: rgba(255, 255, 255, 0.32);

        a {
          color: $neon_green;
          font-family: "Montserrat", sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    height: auto;
    padding-bottom: 100px;
    margin-top: 100px;
    gap: 30px;

    h1 {
      font-size: 24px;
    }

    .mint_body {
      flex-direction: column;

      .form_wrapper {
        width: 100%;
      }

      .cart_wrapper {
        width: 100%;
      }
    }
  }
}
