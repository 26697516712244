.box{
    width: 460px;
    background: radial-gradient(circle at center,rgba(0, 45, 21, 1), rgba(0, 6, 3, 0));
    border-radius: 10px;
    margin: 20px;
    position: relative;

    .heading{
        height: 85px;
        display: flex;
        padding: 10px;
        flex-direction: row;
        justify-content: flex-start;
        border-radius: 10px;
        align-items: center;
        background-color: rgba(146, 184, 98,0.3);
        gap: 10px;

        .image{
            height:60px;
            width: 60px;
            border-radius: 8px;
            object-fit: cover;
        }

        .top {
            font-size: 10px;
            font-weight: 300;
        }

        .bottom{
            font-size: 16px;
            font-weight: 400;
        }

        .token{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 6px;

            div{
                padding: 6px;
                font-size: 16px;
                border-radius: 8px;
                background-color: rgba($color: #000000, $alpha: 0.3);
            }
        }

        .balance{
            background-color: rgba($color: #000000, $alpha: 0.3);
            text-align: center;
            padding: 16px 26px;
            border-radius: 8px;
        }

        .type{
            background-color: rgba($color: #000000, $alpha: 0.3);
            text-align: center;
            padding: 16px 24px;
            border-radius: 8px;
        }
    }

    .body{
        padding: 30px 20px 40px 20px;
        text-align: left;
        
        h3{
            font-size: 20px;
            padding: 10px;
            font-weight: 500;
        }

        p{
            font-size: 16px;
            font-weight: 200;
            padding: 10px;
        }
        
    }

    @media screen and (min-width:420px) and (max-width:540px){
        width: 380px;
        margin: 20px 0;
        
        .heading{
            height: 65px;
            gap: 14px;
        
            .image{
                width: 55px;
                height: 55px;
                
            }

        .top {
            font-size:8px;
        }

        .bottom{
            font-size: 10px;
        }

        .token{

            div{
                padding: 13%;
                font-size: 8px;            
            }
        }
        .balance{
            padding: 12px 24px;
        }

        .type{
            padding: 12px 24px;
            border-radius: 8px;
        }
    }

    .body{
        padding: 20px;
        h3{
            font-size: 15px;
            padding: 6px;
        }

        p{
            font-size: 12px;
            padding: 8px;
        }
    }
}

@media screen and (max-width:420px){
    width: 90%;
    .heading{
        height: 55px;
        gap: 10px;
    
        .image{
            width: 40px;
            height: 40px;
        }

    .top {
        font-size:6px;
    }

    .bottom{
        font-size: 8px;
    }

    .token{

        div{
            padding: 13%;
            font-size: 8px;
        }
    }
    .balance{
        padding: 4% 4%;
        border-radius: 8px;
    }

    .type{
        padding: 4% 4%;
        border-radius: 8px;
    }
}

.body{
    padding: 20px;
    h3{
        font-size: 12px;
        padding: 6px;
    }

    p{
        font-size: 9px;
        padding: 8px;
    }
}
}

    .link{
        position: absolute;
        bottom: 0;
        right:0;
        cursor: pointer;
        margin-bottom: 15px;
        margin-right: 15px;
        color: #72FF1C;
        font-size: 10px;
        display: flex;
        align-items: center;

        svg{
            padding: 3px;
        }

        @media screen and (max-width:540px) {
            position:static;
            justify-content: center;
            text-align: center;
            font-size: 8px;
        }
    }
}