@import "../mixin";

.box {
  width: 424px;
  background: radial-gradient(rgba(0, 45, 21, 1), rgba(0, 42, 20, 0.6));
  border-radius: 8px;
  margin: 20px;
  position: relative;
  text-align: center;
  padding: 30px 42px;

  .body {

    @include center(column);
    gap: 16px;

    div {
      gap: 24px;

      label {
        flex-basis: 0% !important;
      }

       input {

        flex-basis: 100% !important;
       }
    }

    h1 {
      font-family: "Lexend Tera", sans-serif;
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 16px;

      @media screen and (max-width:500px){
        font-size: 16px;
      }
    }

    .email_input {
      label {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }

    .agreement {
      width: 100%;
      @include center;
      gap: 24px;

      label {
        flex-basis: unset !important;
        text-align: left;

        a {
          color: white;
          font-family: "Montserrat", sans-serif;
        }
      }

      input {
        margin-left: 12px;
        flex-basis: unset !important;
      }
    }

    .connect_wallet {
      width: 100%;
      margin-bottom: 10px;
      @include center;
      gap: 24px;

      label {
        position: relative;
        @include center;

        svg {
          height: 30px;
          width: 30px;
          stroke: white;
        }
      }

      button {
        @include hide-scrollbar;
        font-family: "Lexend Tera", sans-serif;
        color: white;
        border: 1px solid white;
        border-radius: 8px;
        padding: 6px 20px;
        width: 100%;
        font-size: 1rem;
        background: transparent;
        overflow: scroll;
        transition: border 0.3s ease;

        &:focus {
          border: 1px solid #78d03e;
          outline: none;
        }
      }
    }

    .login_button {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      @include center;
      gap: 20px;

      @media screen and (max-width:500px){
        font-size: 12px;
      }

      svg {
        stroke-width: 0.7;
        height: 30px;
        width: 30px;
        stroke: white;
      }
    }
  }

  .sign_link {
    margin-top: 24px;
    color: rgba(255, 255, 255, 0.32);

    a {
      color: #72ff1c;
      font-family: "Montserrat", sans-serif;
    }
  }

  @media screen and (min-width:750px) and (max-width:1018px){
    width: 300px;
  }

  @media screen and (min-width:478px) and (max-width:750px){
    padding: 30px;

    .body {
      h1 {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 478px) {
    min-width: 90%;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 0;
    padding: 20px;

    .body {
      h1 {
        font-size: 1rem;
      }

      .connect_wallet {
        gap: 0;
        label {
          svg {
            display: none;
          }
        }
      }

      .login_button {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
