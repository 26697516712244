@import "../mixin";
@import "../variables";

.light {
  .preview_wrapper {
    background-color: $preview_wrapper_bg_light;
    color: black;

    .preview_page {
      background-color: #fff;
    }
  }
}

.dark {
  .preview_wrapper {
    background-color: $preview_wrapper_bg_dark;
    color: white;

    .preview_page {
      background-color: $preview_bg_dark;
    }
  }
}

.pink {
  .preview_wrapper {
    background-color: $preview_wrapper_bg_pink;
    color: white;

    .preview_page {
      background-color: $preview_bg_pink;
    }
  }
}

.page {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 998;

  & * {
    ::-webkit-scrollbar-track {
      visibility: visible;
      background: lightgray;
      border-radius: 0;
    }

    ::-webkit-scrollbar {
      background-color: lightgray;
      border-radius: 0;
      width: initial;
      height: initial;
    }

    ::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 0;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: darken($color: gray, $amount: 20%);
    }
  }

  aside {
    position: absolute;
    right: 10px;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 999;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    &:first-child {
      top: 10px;
    }

    &:last-child {
      bottom: 10px;
    }
  }

  .header_banner {
    @include center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 6px;
    row-gap: 10px;
    background-color: #000;
    color: #fff;
    padding: 20px 10px;

    span {
      text-align: center;
      margin-right: 10px;

      & > a {
        margin-left: 6px;
      }
    }

    a {
      text-decoration: underline;
      color: white;
    }

    .report_link {
      @include center;
      gap: 6px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .footer {
    position: absolute;
    top: unset;
    bottom: 0;
    width: 100%;
    justify-content: center;
  }

  .preview_wrapper {
    height: 100%;

    .preview_page {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      @include center(column);
      justify-content: flex-start;
      margin: 0 120px;
      padding: 40px 60px 130px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);

      .header_image {
        width: 60%;
        max-width: 100%;
        height: auto;
        border-radius: 10px;
      }

      .title_container {
        align-self: center;
        text-align: center;
        font-size: 28.8px;

        h1 {
          font-weight: 700;
          margin: 0;
        }
      }

      .content {
        width: 100%;
        align-self: center;
        text-align: center;
        font-size: 19.2px;
        font-weight: 400;
        padding: 0 40px;
      }

      .section_images {
        width: 100%;
        @include center;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 60px;
        row-gap: 20px;
        padding: 0 40px;

        img {
          width: 30%;
          max-width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }

      h2 {
        align-self: flex-start;
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
      }

      .header_image,
      .title_container,
      .content,
      .section_images,
      .attachment {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 798px) {
        padding-bottom: 90px;

        .title_container {
          font-size: 1.5rem;
        }

        .content {
          font-size: 1rem;
        }

        h2 {
          font-size: 1.2rem;
        }
      }

      @media screen and (max-width: 500px) {
        margin: 0;
        padding: 0 10px 90px;

        .header_banner {
          width: calc(100% + 20px);
        }

        .header_image {
          width: 100%;
          margin-bottom: 20px;
        }

        .title_container {
          font-size: 1.2rem;
        }

        .content {
          font-size: 0.8rem;
          padding: 0 20px;
        }

        .section_images {
          padding: 0 20px;

          img {
            width: 45%;
          }
        }

        h2 {
          font-size: 1rem;
        }
      }
    }
  }
}
