@import "../mixin";
@import "../variables";

.cart {
  @include center(column);
  align-items: flex-start;
  gap: 18px;
  border-radius: 8px;
  padding: 30px;
  width: 100%;

  .header {
    @include center;
    justify-content: flex-start;
    gap: 24px;

    svg {
      width: 24px;
      height: 24px;
      fill: $neon_green;
    }

    h2 {
      font-weight: 500;
      font-size: 24px;

      @media screen and (max-width:500px){
        font-size: 20px;
      }
    }
  }

  .items {
    width: 100%;
    @include center;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    p {
      font-size: 14px;
      flex: 0.2;
      text-align: left;
    }

    .item {
      flex: 0.8;
      height: 70px;
      max-height: 70px;
      overflow: auto;
      @include hide-scrollbar;
      font-weight: 300;
      @include center(column);
      justify-content: flex-start;
      align-items: flex-end;
      gap: 6px;
    }
  }

  .prices {
    width: 100%;
    @include center;
    justify-content: flex-start;
    font-size: 14px;

    p:last-child {
      width: 60%;
      text-align: right;
    }

    p:first-child {
      width: 40%;
      text-align: left;
      font-weight: 500;
    }
  }

  .total {
    width: 100%;
    @include center;
    justify-content: flex-start;
    font-size: 14px;

    p {
      span {
        font-weight: 500;
        background: linear-gradient(180deg, #72ff1c -19.33%, #015828 100%),
          linear-gradient(0deg, #ffffff, #ffffff);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p:last-child {
      width: 60%;
      font-weight: 500;
      font-size: 20px;
      border-top: 1px solid $neon_green;
      padding-top: 12px;
      text-align: right;
    }

    p:first-child {
      width: 40%;
      text-align: left;
      font-weight: 500;
    }
  }

  & > p {
    margin-top: 6px;
    color: rgba(255, 255, 255, 0.55);
    font-size: 8px;
    @include center;

    svg {
      width: 16px;
      height: 16px;
      stroke: rgba(255, 255, 255, 0.55);
      margin-right: 4px;
    }
  }
}
