@import "variables";
@import "mixin";

.input_group {
  width: 100%;
  @include center;
  gap: 24px;

  label {
    @include center;
    gap: 12px;
    justify-content: flex-start;

    p {
      order: 2;
    }

    i {
      order: 1;

      svg {
        height: 40px;
        width: 40px;
        stroke: white;
      }
    }
  }

  input {
    font-family: "Lexend Tera", sans-serif;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 6px 20px;
    width: 100%;
    font-size: 1rem;
    background-color: #d9d9d9;
    transition: border 0.3s ease;

    &:focus {
      border: 2px solid #78d03e;
      outline: none;
    }
  }

  @media screen and (max-width: 478px) {
    margin-bottom: 12px;
    flex-direction: column;
    gap: 0;

    label {
      width: 100%;

      i {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}

.loader_container {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);

  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;

    &::after,
    &::before {
      content: "";
      background: transparent;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      border-width: 3px;
      border-color: darken($neon_green, 5%) darken($neon_green, 15%) transparent
        transparent;
      border-style: solid;
      border-radius: 20px;
      box-sizing: border-box;
      top: 0;
      left: 0;
      animation: around 0.7s ease-in-out infinite;
    }

    &::after {
      animation: around 0.7s ease-in-out 0.1s infinite;
      background: transparent;
    }
  }

  .darken_loader {
    &::after,
    &::before {
      border-color: darken($neon_green, 25%) darken($neon_green, 35%)
        transparent transparent;
    }
  }
}

.divider {
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: $neon_green;
  margin: 4px 0;
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
