@import "../mixin";
@import "../variables";

.section{
  background-color: #001c0d;
  min-height: 82vh;
  position: relative;


  .table_page {
    padding: 0 40px 150px 40px;
    border-radius: 8px;

    margin-top: 100px;

    @media screen and (max-width:750px){
      padding: 0px 20px 100px 20px;
    }

    @media screen and (min-width:1400px){
      max-width: 1400px;
      margin: 100px auto 0px auto;

    }

    @media screen and (max-width: 1400px) {
      max-width: 100%;
    }

    h1{
      text-align: center;
      z-index: 1;
      font-size: 32px;
      position: relative;
      margin-bottom: 30px;

      @media screen and (max-width:750px) {
          font-size: 24px;
      }
    }

    .table_wrapper {
      border-radius: 8px;
      background: radial-gradient(196.51% 196.51% at 50% 55.23%, #002D15 0%, rgba(0, 6, 3, 0) 100%);
      background-size: 200%;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      .loader {
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;

        div{
          position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
        }
      }

      .table {
        position: relative;
        z-index: 1;
        font-size: 16px;
        border-radius: 8px;


        >:first-child{
          min-height: 400px;
        }

        >:nth-child(2){
          nav{
            border-top-style: none;
          }
        }

        .status {
          text-transform: uppercase;
          font-size: 0.8rem;
          letter-spacing: 1px;
          font-weight: 200;
          color: rgba(106, 106, 99, 1);
        }

        .blocked {
          color: red;
        }

        .link_edit {
          p {
            cursor: pointer;
          }
        }

        .link {
          text-decoration: underline;
          color: $neon_blue;
          transition: all 0.2s ease-in-out;

          &:hover {
            color: $neon_green;
          }
        }

        .actions {
          @include center;
          justify-content: space-between;
          width: 100%;
          padding: 12px 0;
          gap: 14px;

          .text {
            padding-bottom: 2px;
            overflow: auto;
            max-width: 80%;
            white-space: nowrap;
          }

          .value {
            padding-bottom: 2px;
            overflow: auto;
            width: 80%;
            max-width: 80%;
            white-space: nowrap;
            text-align: center;
          }

          .edits {
            border: none;
            background: transparent;
            outline: none;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:disabled {
              cursor: not-allowed;
            }

            svg {
              width: 18px;
              height: auto;
              transition: all 0.2s ease-in-out;
              fill: white;

              &:hover {
                fill: $neon_green;
              }
            }

            &:hover {
              color: darken(white, 10%);
            }

            .promote {
              flex: 0.6;
            }
          }

          .promote {
            padding: 6px 12px;
            font-size: 14px;
            width: fit-content;

            div {
              font-size: 6px;
              bottom: -40%;
              transform: scale(0.65);
            }

            @media screen and (max-width: 750px) {
              padding: 6px 10px;
            }
          }
        }
      }

      .extra {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 20px 20px;
        width: 100%;
        @include center;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 750px) {
          flex-direction: column;
          gap: 20px;
        }

        .stats {
          @include center;
          gap: 40px;

          @media screen and (max-width:750px){
            gap: 20px;
          }

          .stat_card {
            padding: 10px 20px;
            border-radius: 8px;
            @include center(column);
            gap: 6px;
            background-color: rgba(0, 0, 0, 0.3);
            text-align: center;

            & > span {
              font-family: "Lexend Tera", sans-serif;
              font-weight: 300;
              font-size: 10px;
            }

            p {
              font-size: 16px;
            }

            @media screen and (max-width:400px){
              padding: 8px 16px;
              & > span {
                font-size: 8px;
              }
            }

          }
        }

        .new_gate_btn {
          font-size: 16px;
          padding: 10px 42px;

          @media screen and (max-width:750px){
            font-size: 12px;
          }
        }
      }
    }
  }
}

.expandedDiv{
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: left;

  p{
    font-size: 14px;
    padding: 5px;
    color: white;
    span{
      color: rgb(83, 217, 83);
    }
  }
}
