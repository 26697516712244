@import "../mixin";
@import "../variables";

.site_page {
  width: 100%;
  margin: 100px auto 0;
  padding-bottom: 100px;
  @include center(column);
  gap: 60px;
  background-color: #001c0d;

  h1 {
    text-align: center;
    font-size: 32px;
    z-index: 1;
  }

  .site_body {
    @include center;
    align-items: flex-start;
    gap: 30px;
    position: relative;
    z-index: 2;
    padding: 0 20px;

    .form_wrapper {
      border-radius: 8px;

      .header_image {
        justify-self: center;
        align-self: center;
        @include center(column);
        gap: 12px;

        img {
          max-width: 70%;
          height: auto;
          min-height: 100px;
        }
      }

      .input_with_children,
      .text_input {
        .radio_input {
          @include center;
          justify-content: flex-start;
          min-width: 40%;
          max-width: fit-content;
          gap: 10px;

          & * {
            transition: border 0.2s ease-in-out;
          }

          .single_radio {
            width: 100%;
            @include center;
            justify-content: flex-start;
            gap: 10px;

            input {
              width: 10%;
            }

            input[type="radio"][value="dark"] {
              accent-color: black;
            }

            input[type="radio"][value="pink"] {
              accent-color: pink;
            }

            label {
              width: fit-content;
            }
          }

          .radio {
            @include center(column);
            flex-direction: column-reverse;
            justify-content: space-between;
            gap: 4px;
            width: 25%;
            max-width: 25%;
          }

          .single_radio,
          .radio {
            svg {
              height: 24px;
              width: 24px;
              padding: 4px;
              border-radius: 4px;
              border: 1px solid transparent;
              cursor: pointer;

              &:hover {
                border: 1px solid white;
              }
            }

            input:checked + svg {
              border: 1px solid white;
            }
          }
        }
      }

      .text_input {
        justify-content: space-between;

        label {
          flex-basis: unset;
          width: 30%;
        }

        input,
        textarea,
        div {
          width: 70%;
          max-width: 70%;
          flex-basis: unset;
        }
      }

      .input_with_children {
        input:not([type="radio"]) {
          width: 20%;
          max-width: 20%;
          cursor: pointer;
        }

        .radio_input {
          width: 30%;
          max-width: 70%;
        }

        .theme_input {
          max-width: 40%;
          width: 100%;
        }
      }

      .theme_input {
        .radio_input {
          width: 100%;
          max-width: 100%;
          justify-content: flex-start;
          gap: 30px;

          .single_radio {
            width: fit-content;
          }
        }
      }

      .theme_input_mobile {
        display: none;
      }

      .section_images {
        @include center;
        align-items: stretch;
        justify-content: space-between;

        .section_image {
          width: 25%;

          img {
            max-width: 120px;
          }
        }
      }

      .attachment_note {
        font-size: 14px;
        opacity: 0.7;
      }

      .buttons {
        margin-top: 18px;
        width: 100%;
        @include center;
        justify-content: space-between;
        flex-wrap: wrap;

        .button {
          @include center;
          gap: 6px;
          background: transparent;
          box-shadow: unset;
          padding: unset;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    padding-bottom: 100px;
    margin-top: 100px;
    gap: 30px;

    h1 {
      font-size: 24px;
    }

    .site_body {
      .form_wrapper {
        .header_image {
          img {
            min-height: unset;
          }
        }

        .input_with_children {
          .radio_input {
            width: 80%;
          }
        }

        .section_images {
          flex-wrap: wrap;
          justify-content: center;
          gap: 30px;

          .section_image {
            min-width: 40%;

            img {
              max-width: 80px;
            }
          }
        }

        .buttons {
          gap: 14px;

          .button {
            width: 40%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .site_body {
      .form_wrapper {
        .theme_input_mobile {
          display: flex;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    h1 {
      font-size: 18px;
    }

    .site_body {
      .form_wrapper {
        .section_images {
          .section_image {
            min-width: 100%;

            img {
              max-width: 60%;
            }
          }
        }
      }
    }
  }
}
