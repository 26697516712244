@import "variables";
@import "mixin";

.editable_text {
  @include center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  hyphens: none;
  word-break: keep-all;

  a{
    hyphens: none;
  }

  .text,
  input {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    @include hide-scrollbar;
    text-align: left;
    font-size: 12px;
    font-weight: 200;
    border: none;
    background: transparent;
    color: white;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: darken(white, 10%);
    }

    &:focus {
      padding: 6px;
      border: 1px solid $neon_green;
      border-radius: 4px;
      font-weight: 200;
      outline: none;
    }
  }

  .loader {
    right: 36px;
  }

  .icons {
    justify-self: flex-end;
    flex: 0.4;
    @include center;
    justify-content: space-evenly;
    gap: 6px;
    transition: all 0.2s ease-in-out;

    .icon {
      @include center;
      border: none;
      background: transparent;
      color: white;
      outline: none;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      svg {
        width: 16px;
        height: auto;
        transition: all 0.2s ease-in-out;
        fill: white;

        &:hover {
          fill: $neon_green;
        }
      }

      &:hover {
        color: darken(white, 10%);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .cross_red {
      svg:hover {
        fill: red;
      }
    }
  }
}
