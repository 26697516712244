.section{
    font-family: "Monserrat", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    padding: 0 40px 20px 40px;
    align-items: center;
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;

    div{
        font-family: 'Montserrat';
        font-size: 14px;
    }

    @media  screen and (max-width:910px){
        flex-direction: column-reverse;
    }

    li {
        list-style: none;
    }

    a{
        color: white;
        text-decoration: none;
        font-family: 'Montserrat';

        &:hover {
            color: #78d03e;

          }


    }

    @media (min-width: 1400px) {
        max-width: 1400px;
        margin: auto;
    }

        @media screen and (max-width: 1400px) {
         max-width: 100%;
        }

    .footer_links{
        z-index: 1;
        display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 20px;

    li{
        position: relative;
        &:hover{
            a{
                &:hover{
                    color: #78d03e;
                  }

                  &::after{
                      content:"";
                      position: absolute;
                      border-radius: 8px;
                      left: 0;
                      bottom: 0;
                      margin-bottom: -5px;
                      height: 3px;
                      width: 0;
                      background-color: #78d03e;
                      transition: all ease-in-out 0.1s;
                    }

                    &:hover::after{
                      width: 100%;
                    }
            }
          }

          &::after{
              content:"";
              position: absolute;
              border-radius: 8px;
              left: 0;
              bottom: 0;
              margin-bottom: -5px;
              height: 3px;
              width: 0;
              background-color: #78d03e;
              transition: all ease-in-out 0.1s;
            }

            &:hover::after{
              width: 100%;
            }
    }
    }


    .icons {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:20px;

    svg {
        width: 26px;
        height: auto;
    }
    }
}
