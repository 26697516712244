@import "../variables";
@import "../mixin";

.contract_page {
  position: relative;
  @include center(column);
  justify-content: flex-start;
  width: 100%;
  margin: 100px 0;
  gap: 60px;
  background-color: #001c0d;

  & * {
    z-index: 1;
  }

  .forms {
    @include center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 40px;

    .form {
      flex: 0.2 1 300px;
      justify-content: flex-start;
      padding: 40px 32px;
      border-radius: 8px;

      h3 {
        font-weight: 500;
        font-size: 20px;
      }

      .note_box {
        max-width: 300px;
      }

      .input_box {
        label {
          font-size: 14px;
        }
      }

      button {
        padding: 12px 24px;

        div {
          right: 2px;
        }
      }
    }
  }
}
