@import "mixin";
@import "variables";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// html {
//   font-size: 62.5%;
// }

body {
  z-index: -1;
  position: relative;
  overflow-x: hidden;
  background: black;
  color: white;
  margin: 0 auto;
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-width: 1400px) {
    max-width: 100%;
  }
}

a,
button {
  cursor: pointer;
  font-family: "Lexend Tera", sans-serif;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

label[aria-disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
}

*:disabled,
*[aria-disabled="true"],
*[aria-disabled="true"] > * {
  outline: none;
  opacity: 0.5;
  cursor: not-allowed;
}

input {
  accent-color: $neon_green;
}

select {
  border-radius: 8px;
  padding: 5px 0 5px 10px;
  width: fit-content;
  min-width: 70px;
  background-color: rgba(255, 255, 255, 0.24);
  color: white;
  font-weight: 200;
  border: 2px solid transparent;
  transition: border 0.3s ease;

  &:focus {
    border: 2px solid $neon_green;
    outline: none;
  }

  option {
    color: black;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

::selection {
  background: $neon_green;
  color: black;
  text-shadow: none;
}

::-webkit-scrollbar-track {
  visibility: hidden;
  background: transparent;
  border-radius: 20px;
}

::-webkit-scrollbar {
  background-color: rgba(217, 217, 217, 0.15);
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 87, 40, 1);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 87, 40, 0.8);
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.green {
  color: $neon_green !important;
}

.orange {
  color: rgb(255, 166, 1) !important;
}

.radial_gradient {
  // background: radial-gradient(
  //   circle at center,
  //   rgb(0, 66, 31),
  //   rgba(0, 6, 3, 0)
  // );
  background: radial-gradient(
    196.51% 196.51% at 50% 55.23%,
    rgb(0, 34, 16) 0%,
    rgba(0, 6, 3, 0) 100%
  );
}

.bg_gradient {
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(4, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
}

.gradient_btn {
  padding: 16px 38px;
  background: linear-gradient(91deg, #005728 0%, #78d03e 51%, #005728 100%);
  box-shadow: 0px 0px 4px #005728;
  background-size: 200%;
  border: none;
  z-index: 2;
  position: relative;
  border-radius: 8px;
  color: white;
  transition: background-position 0.3s ease;
  font-size: 12px;

  @media screen and (max-width: 750px) {
    font-size: 8px;
  }

  @media screen and (min-width: 750px) and (max-width: 1040px) {
    font-size: 10px;
  }

  &:hover:not(:disabled) {
    background-position: right center;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.8;
    box-shadow: none;
  }
}

.btnWithTier {
  position: relative;
  width: 100%;
  padding: 12px 26px;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  letter-spacing: 2px;
  border: 1px solid;
  outline: none;
  background: transparent;

  @media screen and (max-width: 750px) {
    font-size: 8px;
  }

  @media screen and (min-width: 750px) and (max-width: 1040px) {
    font-size: 10px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  .tier {
    position: absolute;
    bottom: -25%;
    right: -40px;
    width: fit-content;
    @include center;
    margin: 0 20px;
    padding: 3px 6px;
    gap: 4px;
    font-size: inherit;
    text-transform: uppercase;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;

    svg {
      width: 8px;
      height: auto;
      transition: all 0.2s ease-in-out;

      &:hover {
        fill: $neon_green;
      }
    }

    span {
      font-size: 10px;
    }

    @media screen and (max-width: 750px) {
      font-size: 5px;
    }

    @media screen and (min-width: 750px) and (max-width: 1040px) {
      font-size: 5px;
    }
  }

  .ineligible {
    background: rgba(255, 0, 0, 0.5);
  }

  .basic {
    background: rgba(96, 96, 96, 1);
  }

  .premium {
    background: linear-gradient(91deg, #005728 0%, #78d03e 51%, #005728 100%);
    box-shadow: 0px 0px 4px #005728;
    background-size: 200%;

    svg {
      fill: white;
    }
  }

  .advanced {
    background: linear-gradient(91deg, #005728 0%, #78d03e 51%, #005728 100%);
    box-shadow: 0px 0px 4px #005728;
    background-size: 200%;

    svg {
      fill: rgba(248, 184, 78, 1);
    }
  }
}

.blocked {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.rdt_TableCol_Sortable > div {
  overflow: unset;
  white-space: wrap !important;
  text-overflow: unset;
}
