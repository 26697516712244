@import "../variables";
@import "../mixin";

.section {
  position: relative;
  background-image: $bgImagePath;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 80px;


  @media screen and (max-width: 650px) {
    background-image: $mobileBgImagePath;
  }

  @media screen and (max-width: 500px) {
    margin: 80px 0 50px 0px;
  }

  .auth_gradient {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 87, 40, 0.3),
      rgba(0, 0, 0, 1)
    );
  }

  .container {
    @include center;
    flex-wrap: wrap;
    padding: 30px 0 150px;
    gap: 40px;
    overflow: hidden;

    @media screen and (min-width:750px) and (max-width:1018px) {
      gap: 20px;
    }

    .auth_image {
      height: auto;
      z-index: 2;

      @media screen and (min-width: 750px) and (max-width: 1440px) {
        width: 30%;
        max-width: 30%;
      }

      @media screen and (min-width: 400px) and (max-width: 750px) {
        width: 300px;
      }

      @media screen and (max-width: 400px) {
        width: 60%;
      }

      @media screen and (max-width: 330px) {
        width: 50%;
      }
    }

    .auth_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      max-width: 40%;
      gap: 20px;
      position: relative;
      z-index: 2;

      .box{
        width: 400px;
      }


    }

    @media (min-width: 1400px) {
      margin: auto;
    }

    @media screen and (max-width: 1400px) {
      max-width: 100%;
    }
    

    @media screen and (max-width: 750px) {
      flex-direction: column;
      row-gap: 80px;

      .auth_box {
        order: 2;
        margin-top: -100px;
        max-width: 100%;
      }

      .auth_image {
        order: 1;
        max-width: 100%;
      }
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      row-gap: 80px;

      .auth_box {
        order: 2;
        margin-top: -90px;
        max-width: 100%;
      }

      .auth_image {
        order: 1;
        max-width: 100%;
      }
    }

    @media screen and (max-width: 750px) {
      padding: 30px 0 50px;
    }

    @media screen and (max-width: 448px) {
      padding: 0;

      .auth_box {
        padding: 20px;
      }
    }
  }
}

