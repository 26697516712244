.eclipseHero1 {
  position: absolute;
  margin-right: 700px;
  margin-bottom: 105px;
  z-index: 0;
  opacity: 0.2;

  @media screen and (max-width: 1000px) {
    display: none;
  }

  @media screen and (min-width: 1700px) {
    margin-bottom: 380px;
    left: -10%;
  }
}

.eclipseHero2 {
  position: absolute;
  margin-right: 850px;
  margin-bottom: 180px;
  z-index: 0;
  opacity: 0.2;

  @media screen and (max-width: 1000px) {
    display: none;
  }

  @media screen and (min-width: 1700px) {
    margin-bottom: 480px;
    left: -10%;
  }
}

.eclipseLeft {
  position: absolute;
  width: 30%;
  left: 0;
  z-index: 1;
  height: 100%;
  opacity: 0.5;
}

.eclipseRight {
  position: absolute;
  width: 40%;
  right: 0;
  z-index: 1;
  height: 100%;
  opacity: 0.5;

  // @media screen and (max-width: 750px) {
  //   margin-top: 100px;
  // }
}

.eclipseTiersLeft {
  position: absolute;
  width: 30%;
  left: 0;
  z-index: 0;
}

.eclipseTiersRight {
  position: absolute;
  width: 40%;
  right: 0;
  z-index: 0;

  @media screen and (max-width: 750px) {
    margin-top: 100px;
  }
}

.eclipseFeaturedLeft {
  position: absolute;
  left: 0;
  z-index: 0;
  width: 40%;

  @media screen and (max-width: 800px) {
    top: 20%;
    width: 80%;
  }
}

.eclipseFeaturedRight {
  position: absolute;
  right: 0;
  z-index: 0;
  margin-top: 300px;
  width: 40%;

  @media screen and (max-width: 800px) {
    top: 40%;
    width: 80%;
  }
}

.eclipseContentLeft {
  position: absolute;
  width: 30%;
  left: 0;
  z-index: 0;
}

.eclipseContentRight {
  position: absolute;
  width: 40%;
  right: 0;
  z-index: 0;
}
